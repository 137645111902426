<template>
	<v-container
		fluid
		:style="
			`background-color:${themeColors.color1}; color:${themeColors.color2};`
		"
	>
		<banner
			:title="projectData.projectName"
			:subtitle="projectData.address"
			:icon="['fas', 'map-marker-alt']"
			:background-color="themeColors.color1"
			:text-color="themeColors.color2"
		/>

		<v-row>
			<v-col cols="12" sm="12" md="6">
				<p class="text-body-1 mb-1">
					<v-avatar class="mr-1" :color="themeColors.color3" size="24">
						<span
							:style="`font-size: 15px; color: ${themeColors.buttonTextColor}`"
							>{{ updateStepNumber(1) }}</span
						>
					</v-avatar>
					Seleccionar
				</p>
				<v-row no-gutters>
					<v-col>
						<v-select
							v-model="selectedModel"
							no-data-text="No hay datos disponibles"
							class="mb-1"
							:class="
								$vuetify.breakpoint.name == 'xs' ||
								$vuetify.breakpoint.name == 'sm'
									? 'mb-1'
									: 'mb-1'
							"
							outlined
							dense
							hide-details
							:disabled="modelsDisabled"
							:items="models"
							placeholder="Seleccionar"
							:background-color="themeColors.selectColor"
							:color="themeColors.selectTextColor"
							:style="`color:${themeColors.selectTextColor}`"
							:attach="true"
							@change="updateApartmentsSelect"
						>
						</v-select>
					</v-col>
				</v-row>
				<v-row class="mb-1" align="center" justify="center">
					<div style="width:100%">
						<v-row>
							<v-col cols="4" align="center" justify="center">
								<p class="font-weight-black">Superficie Total</p>
								{{
									new Intl.NumberFormat('es-CL', {
										maximumFractionDigits: 2,
									}).format(getApartmentInfo.surface)
								}}
								m<sup>2</sup></v-col
							>
						</v-row>
					</div>
				</v-row>
				<v-row align="center" justify="center" no-gutters>
					<v-col>
						<image-dialog
							:thumb="`${getApartmentInfo.image}`"
							:full="`${getApartmentInfo.image}`"
							:mobile="
								this.$vuetify.breakpoint.name == 'xs' ||
								this.$vuetify.breakpoint.name == 'sm'
									? true
									: false
							"
							:style="
								`position:relative; max-height: 400, max-width: ${imgWidth}; margin-bottom: 8px;`
							"
						></image-dialog>
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="12" sm="12" md="6">
				<p v-if="!hideSelectedAssets" class="text-body-1 mb-5">
					<v-avatar class="mr-2" :color="themeColors.color3" size="24">
						<span
							class="white--text"
							:style="`font-size: 15px; color: ${themeColors.buttonTextColor}`"
							>{{ updateStepNumber() }}</span
						>
					</v-avatar>
					Bienes cotizados
				</p>
				<v-row
					v-if="!hideSelectedAssets"
					class="mb-5"
					align="center"
					justify="center"
					no-gutters
				>
					<div :style="`width:95%; color:${themeColors.color2}`">
						<v-row>
							<v-col
								cols="6"
								:style="`border: 1px ridge ${themeColors.color2}`"
								align="center"
								justify="center"
								>Tipo bien <br
							/></v-col>
							<v-col
								cols="6"
								:style="`border: 1px ridge ${themeColors.color2}`"
								align="center"
								justify="center"
								>Precio lista <br
							/></v-col>
						</v-row>
						<v-row>
							<v-col
								cols="6"
								:style="`border: 1px ridge ${themeColors.color2}`"
								align="center"
								justify="center"
								>{{
									`${getApartmentInfo.assetType} ${getApartmentInfo.type}`
								}}</v-col
							>
							<v-col
								cols="6"
								:style="`border: 1px ridge ${themeColors.color2}`"
								align="center"
								justify="center"
							>
								{{
									new Intl.NumberFormat('es-CL', {
										maximumFractionDigits: 2,
									}).format(getApartmentInfo.price)
								}}
								UF
								<v-tooltip v-if="getApartmentInfo.discount" bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-chip v-bind="attrs" small v-on="on">
											<font-awesome-icon
												style="margin-right: 3px"
												:icon="['fas', 'tag']"
											/>
											dto
										</v-chip>
									</template>
									<span
										v-if="
											getApartmentInfo.discount[propertyNames.amountType] ==
												'PORCENTAJE'
										"
										v-text="
											`descuento ${new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
											}).format(
												getApartmentInfo.discount[
													propertyNames.discountAmount
												] * 100
											)}%`
										"
									></span>
									<span
										v-if="
											getApartmentInfo.discount[propertyNames.amountType] ==
												'CONSTANTE'
										"
										v-text="
											`descuento ${new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
											}).format(
												getApartmentInfo.discount[propertyNames.discountAmount]
											)} UF`
										"
									></span>
									<span
										v-if="
											getApartmentInfo.discount[propertyNames.amountType] ==
												'MONTO'
										"
										v-text="
											`descuento $${new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
											}).format(
												getApartmentInfo.discount[propertyNames.discountAmount]
											)}`
										"
									></span>
									<span
										v-if="
											getApartmentInfo.discount[propertyNames.amountType] ==
												'MONTO_DESCUENTO_NO_AUTORIZADO_BIEN' &&
												getApartmentInfo[propertyNames.nonAuthorizedDiscount] !=
													null
										"
										v-text="
											`descuento ${new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
											}).format(
												getApartmentInfo[propertyNames.nonAuthorizedDiscount] *
													100
											)}%`
										"
									></span>
									<span
										v-if="
											getApartmentInfo.discount[propertyNames.amountType] ==
												'MONTO_DESCUENTO_BIEN' &&
												getApartmentInfo[propertyNames.authorizedDiscount] !=
													null
										"
										v-text="
											`descuento ${new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
											}).format(
												getApartmentInfo[propertyNames.authorizedDiscount] * 100
											)}%`
										"
									></span>
								</v-tooltip>
							</v-col>
						</v-row>
						<v-row>
							<v-col
								cols="6"
								:style="`border: 1px ridge ${themeColors.color2}`"
								align="center"
								justify="center"
								>Total</v-col
							>
							<v-col
								cols="6"
								:style="`border: 1px ridge ${themeColors.color2}`"
								align="center"
								justify="center"
							>
								{{
									new Intl.NumberFormat('es-CL', {
										maximumFractionDigits: 2,
									}).format(getApartmentInfo.price)
								}}
								UF</v-col
							>
						</v-row>
					</div>
				</v-row>
				<p class="text-body-1">
					<v-avatar class="mr-2" :color="themeColors.color3" size="23">
						<span
							:style="`font-size: 15px; color: ${themeColors.buttonTextColor}`"
							>{{ updateStepNumber() }}</span
						>
					</v-avatar>
					Ingresa tus datos de contacto
				</p>
				<quote-form ref="formRef" class="mt-2" />
				<v-row no-gutters>
					<v-col>
						<p v-if="quoteLimit != null && quoteLimit <= 0" style="color: red">
							Ha excedido el número de cotizaciones permitidas por día.
						</p>
						<v-btn
							id="moby-quote-button"
							:color="themeColors.color3"
							block
							x-large
							:style="
								`color: ${
									quoteLimit != null && quoteLimit <= 0
										? '#000'
										: themeColors.buttonTextColor
								}; font-size: 16px`
							"
							:disabled="quoteLimit != null && quoteLimit <= 0 ? true : false"
							@click="$refs.formRef.submitForm()"
						>
							{{ textButton }}
						</v-btn>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
// Model == Type ('tipo')
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import QuoteForm from '@/components/QuoteForm.vue'
import Banner from '@/components/Banner.vue'
import ImageDialog from '@/components/ImageDialog.vue'
let stepNumber = 1
export default {
	name: 'LotePorModelo',
	components: {
		QuoteForm,
		Banner,
		ImageDialog,
	},
	data() {
		return {
			apartmentsRoomsBathrooms: [],
			selectedApartmentsRoomsBathrooms: null,
			apartmentsRoomsBathroomsDisabled: false,
			models: [],
			selectedModel: null,
			modelsDisabled: false,
			apartments: [],
			selectedApartmentId: null,
			apartmentsDisabled: false,
		}
	},
	computed: {
		...mapGetters('filterByModelLote', [
			'getApartmentsRoomsBathrooms',
			'getApartmentsTypologiesByRoomsAmountsAndBathroomsAmounts',
			'getApartmentsByTypologyOnly',
		]),
		...mapGetters('assets', [
			'getApartmentInfo',
			'getApartmentById',
			'getOpportunityStatus',
			'getOpportunityDiscount',
			'getQuoteDiscountInfo',
		]),
		...mapState('assets', ['propertyNames', 'masterAssetType']),
		...mapState('theme', ['themeColors']),
		...mapState('general', [
			'projectData',
			'assetIdParam',
			'typology',
			'roomsAmount',
			'bathroomsAmount',
			'quoteLimit',
			'reservation',
			'hideSelectedAssets',
		]),
		imgWidth() {
			return this.$vuetify.breakpoint.name == 'xs' ? '90%' : 580
		},
		assetSurfaceTitle: function() {
			return this.$vuetify.breakpoint.name == 'xs' ? 'SUPERFICIE' : ''
		},
		assetSurfaceSubtitle: function() {
			return this.$vuetify.breakpoint.name == 'xs' ? '' : 'SUPERFICIE'
		},
		spaceBetweenComponents: function() {
			return this.$vuetify.breakpoint.name == 'xl' ||
				this.$vuetify.breakpoint.name == 'lg'
				? 'mt-10'
				: 'mt-10 mb-3'
		},
		textButton() {
			return this.reservation ? 'Reservar' : 'Cotizar'
		},
		textColor() {
			return {
				'--text-color': this.themeColors.selectTextColor,
			}
		},
	},
	created() {
		if (this.assetIdParam != null) {
			this.setInitDataByAssetId()
			return
		}

		if (this.typology != null) {
			this.setInitDataByTypology()
			return
		}

		if (this.roomsAmount != null && this.bathroomsAmount != null) {
			this.setInitDataByRoomsAndBathroomsAmount()
			return
		}

		this.setInitData()
	},
	mounted() {
		let event = new Event('template-ready', { bubbles: true })
		document.dispatchEvent(event)
	},
	methods: {
		...mapMutations('assets', [
			'setApartmentId',
			'setAssetMasterType',
			'setOpportunityStatus',
		]),
		...mapActions('assets', ['resetSecondaryAssetsToQuote']),
		updateModelSelect(roomsAndBathroomsAmounts) {
			this.models = this.getApartmentsTypologiesByRoomsAmountsAndBathroomsAmounts(
				roomsAndBathroomsAmounts
			)

			if (this.models.length && !this.typology)
				this.selectedModel = this.models[0]

			this.updateApartmentsSelect()
		},
		updateApartmentsSelect() {
			this.apartments = this.getApartmentsByTypologyOnly(
				this.selectedModel.split(','),
				this.selectedApartmentsRoomsBathrooms
			)
			this.apartments = this.apartments.map(asset => {
				const discountData = this.getOpportunityDiscount(
					asset[this.propertyNames.id]
				)
					? this.getOpportunityDiscount(asset[this.propertyNames.id])
					: this.getQuoteDiscountInfo(asset[this.propertyNames.id])
				return { ...asset, ...{ discountData: discountData } }
			})
			if (this.apartments.length) {
				this.selectedApartmentId = this.apartments[
					Math.floor(Math.random() * this.apartments.length)
				][this.propertyNames.id]
				this.setApartmentId(this.selectedApartmentId)
				this.setAssetMasterType(this.getApartmentInfo.assetType)
				if (this.getApartmentInfo.discount) {
					if (
						this.getApartmentInfo.discount[this.propertyNames.discountType] ==
						'GRUPO_BIENES'
					) {
						this.setOpportunityStatus(true)
					} else {
						this.setOpportunityStatus(false)
					}
				} else {
					this.setOpportunityStatus(false)
				}
			}
		},
		setInitDataByAssetId() {
			this.apartmentsRoomsBathrooms = this.getApartmentsRoomsBathrooms()

			this.updateModelSelect('TODOS')

			const asset = this.getApartmentById(this.assetIdParam)

			const type = asset[this.propertyNames.type]
			const roomsAmount = asset[this.propertyNames.roomsAmount]
			const bathroomsAmount = asset[this.propertyNames.bathroomsAmount]

			this.setApartmentId = this.assetIdParam
			this.setAssetMasterType(this.getApartmentInfo.assetType)
			if (this.getApartmentInfo.discount) {
				if (
					this.getApartmentInfo.discount[this.propertyNames.discountType] ==
					'GRUPO_BIENES'
				) {
					this.setOpportunityStatus(true)
				} else {
					this.setOpportunityStatus(false)
				}
			} else {
				this.setOpportunityStatus(false)
			}

			this.selectedApartmentId = this.assetIdParam
			this.selectedApartmentsRoomsBathrooms = { bathroomsAmount, roomsAmount }
			this.selectedModel = type

			this.apartmentsRoomsBathroomsDisabled = true
			this.modelsDisabled = true
			this.apartmentsDisabled = true
		},
		setInitDataByTypology() {
			this.apartmentsRoomsBathrooms = this.getApartmentsRoomsBathrooms(
				this.typology
			)
			if (this.apartmentsRoomsBathrooms.length)
				this.selectedApartmentsRoomsBathrooms = this.apartmentsRoomsBathrooms[0]

			this.selectedModel = String(this.typology)
			this.updateModelSelect('TODOS')

			this.models.push(String(this.typology))
			this.modelsDisabled = true
		},
		setInitDataByRoomsAndBathroomsAmount() {
			this.apartmentsRoomsBathrooms = this.getApartmentsRoomsBathrooms()

			this.selectedApartmentsRoomsBathrooms = {
				bathroomsAmount: this.bathroomsAmount,
				roomsAmount: this.roomsAmount,
			}

			this.updateModelSelect(this.selectedApartmentsRoomsBathrooms)

			this.apartmentsRoomsBathroomsDisabled = true
		},
		setInitData() {
			this.apartmentsRoomsBathrooms = this.getApartmentsRoomsBathrooms()

			if (this.apartmentsRoomsBathrooms.length)
				this.selectedApartmentsRoomsBathrooms = this.apartmentsRoomsBathrooms[0]

			this.updateModelSelect('TODOS')
		},
		colorName(color) {
			if (color == 'Verde') {
				return 'green'
			} else if (color == 'Rojo') {
				return 'red'
			} else {
				return 'blue'
			}
		},
		updateAssetData(item) {
			this.setApartmentId(item)
			this.setAssetMasterType(this.getApartmentInfo.assetType)
			if (this.getApartmentInfo.associationStatus == 'BIEN_DESVINCULADO') {
				this.resetSecondaryAssetsToQuote()
				this.$refs.subAssets.resetInitialData()
			}
			if (this.getApartmentInfo.discount) {
				if (
					this.getApartmentInfo.discount[this.propertyNames.discountType] ==
					'GRUPO_BIENES'
				) {
					this.setOpportunityStatus(true)
				} else {
					this.setOpportunityStatus(false)
				}
			} else {
				this.setOpportunityStatus(false)
			}
		},
		updateStepNumber(num = null) {
			return num ? (stepNumber = num) : (stepNumber += 1)
		},
	},
}
</script>
<style scoped>
.v-application p {
	margin: 0px;
}
</style>
<style>
.v-input
	> .v-input__control
	> .v-input__slot
	> .v-select__slot
	> .v-select__selections
	> .v-select__selection,
.v-select__selection--comma {
	color: var(--text-color) !important;
}
</style>
