import Vue from 'vue'
import PeruData from '@/Dictionaries/Peru.js'
import ChileData from '@/Dictionaries/Chile.js'
import MexicoData from '@/Dictionaries/Mexico.js'

const state = {
	fetchedComunas: [],
	fetchedAges: [],
	fetchedProfessions: [],
	fetchedPurchaseDestinations: [],
	fetchedSexs: [],
	fetchedRentRanges: [],
	validCountryCodes: { chile: 'CL', peru: 'PE', mexico: 'MX' },
	countryCode: null,
	optionalFields: {
		ageRange: { set: null, required: null, serviceName: 'Rango edad' },
		destinationPurchase: {
			set: null,
			required: null,
			serviceName: 'Destino compra',
		},
		commune: { set: null, required: null, serviceName: 'Comuna' },
		profession: { set: null, required: null, serviceName: 'Profesion' },
		sex: { set: null, required: null, serviceName: 'Sexo' },
		rentRange: { set: null, required: null, serviceName: 'Rango renta' },
		observations: { set: null, required: null, serviceName: 'Observaciones' },
		receiveNews: { set: null, required: null, serviceName: 'Recibir noticias' },
	},
	customObservationsQuestion: null,
	fieldValues: {
		rut: null,
		fName: null,
		lName: null,
		email: null,
		phone: null,
		phoneObject: null,
		ageRange: null,
		destinationPurchase: null,
		comuna: null,
		profesion: null,
		sex: null,
		rentRange: null,
		observation: null,
	},
	sendingQuote: false,
	captchaError: false,
	source: null,
	contact: null,
}

const mutations = {
	setCustomObservationsQuestion: (state, payload) => {
		state.customObservationsQuestion = payload
	},
	setFieldValue: (state, { fieldName, value, phoneObject = null }) => {
		state.fieldValues[fieldName] = value

		if (fieldName == 'phone') state.fieldValues.phoneObject = phoneObject
	},
	setComunas: (state, payload) => (state.fetchedComunas = payload),
	setAges: (state, payload) => (state.fetchedAges = payload),
	setProfessions: (state, payload) => (state.fetchedProfessions = payload),
	setPurchaseDestinations: (state, payload) =>
		(state.fetchedPurchaseDestinations = payload),
	setSexs: (state, payload) => (state.fetchedSexs = payload),
	setRentRanges: (state, payload) => (state.fetchedRentRanges = payload),
	setCountry: (state, countryCode) => {
		// Country code must be alpha-2 code (ISO 3166-1)

		if (!Object.values(state.validCountryCodes).includes(countryCode)) {
			// TODO: Show error if not valild country code.
			console.log('[MobySuite Quote] Not valid country code')
			return
		}

		state.countryCode = countryCode
	},
	setOptionalField: (state, { field, isSet, isRequired }) => {
		// If these values are not set in the custom element, then these will be set
		// with the values given in the service (list-projects API response). "set"
		// and "required" values should never stay as "null".

		state.optionalFields[field].set = isSet
		state.optionalFields[field].required = isRequired
	},
	setQuoteStatus: (state, payload) => (state.sendingQuote = payload),
	setCaptchaError: (state, payload) => (state.captchaError = payload),
	setSource: (state, payload) => (state.source = payload),
	setContact: (state, payload) => (state.contact = payload),
}

const getters = {
	getIsChilean: state => state.validCountryCodes.chile === state.countryCode,
	getDNIValidationName: state => {
		switch (state.countryCode) {
			case 'CL':
				return 'rut'
			case 'PE':
				return 'dni'
			case 'MX':
				return 'rfc'
			default:
				return 'rut'
		}
	},
	getValidCountryCodesList: state => Object.values(state.validCountryCodes),
	getSlang: state => {
		switch (state.countryCode) {
			case 'CL':
				return ChileData
			case 'PE':
				return PeruData
			case 'MX':
				return MexicoData
			default:
				return ChileData
		}
	},
}

const actions = {
	async reservationToken({ rootState, rootGetters }, inputs) {
		let assets = []
		let discount = null
		if (rootState.assets.permitMultipleMasterAssetSelection) {
			rootState.assets.selectedMasterAssets.map(asset => {
				assets = assets.concat(
					rootGetters['assets/getSelectedApartmentAndSubAssets'](asset)
				)
			})
			assets.concat(rootState.assets.secondaryAssetsToQuote)
		} else {
			assets = [
				...rootGetters['assets/getSelectedApartmentAndSubAssets'](),
				...rootState.assets.secondaryAssetsToQuote,
			]
			discount = rootState.assets.discountId
		}
		const version = rootState.general.version == 2 ? 'v3' : 'v2'
		await Vue.axios
			.post(`${version}/quotes/reservations`, {
				real_estate: rootState.general.realEstate,
				project_id: rootState.general.projectId,
				project_name: rootState.general.projectData.projectName,
				utm_campaign: rootState.general.utmData.utmCampaign,
				utm_content: rootState.general.utmData.utmContent,
				utm_medium: rootState.general.utmData.utmMedium,
				utm_source: rootState.general.utmData.utmSource,
				utm_term: rootState.general.utmData.utmTerm,
				assets: assets,
				discountId: discount,
				...inputs,
				source: state.source ? state.source : null,
				contact: state.contact ? state.contact : null,
			})
			.then(response => {
				var form = document.createElement('form')
				document.body.appendChild(form)
				form.method = 'post'
				form.action = response.data.url
				var input = document.createElement('input')
				input.type = 'hidden'
				input.id = 'webpay-token'
				input.name = 'token_ws'
				input.value = response.data.token
				form.appendChild(input)
				console.log(response.data)
				form.submit()
				document.body.removeChild(form)
			})
			.catch(error => {
				console.log(error)
			})
	},
	sendForm: ({ commit, rootState, rootGetters, getters }, inputs) => {
		let assets = []
		let discount = null
		if (rootState.assets.permitMultipleMasterAssetSelection) {
			rootState.assets.selectedMasterAssets.map(asset => {
				assets = assets.concat(
					rootGetters['assets/getSelectedApartmentAndSubAssets'](asset)
				)
			})
			assets.concat(rootState.assets.secondaryAssetsToQuote)
		} else {
			assets = [
				...rootGetters['assets/getSelectedApartmentAndSubAssets'](),
				...rootState.assets.secondaryAssetsToQuote,
			]
			discount = rootState.assets.discountId
		}
		const version = rootState.general.version == 2 ? 'v3' : 'v2'
		Vue.axios
			.post(`${version}/quotes`, {
				real_estate: rootState.general.realEstate,
				project_id: rootState.general.projectId,
				utm_campaign: rootState.general.utmData.utmCampaign,
				utm_content: rootState.general.utmData.utmContent,
				utm_medium: rootState.general.utmData.utmMedium,
				utm_source: rootState.general.utmData.utmSource,
				utm_term: rootState.general.utmData.utmTerm,
				assets: assets,
				discountId: discount,
				...inputs,
				source: state.source ? state.source : null,
				contact: state.contact ? state.contact : null,
			})
			.then(response => {
				const apartment = response.data.cotizacion.bienes.find(apartment =>
					rootState.assets.propertyNames.masterAssetTypes.includes(
						apartment['tipoBien']
					)
				)
				let payload = {}
				payload = {
					success: true,
					title: 'ÉXITO',
					body: `${getters.getSlang.formData.quoteName} realizada con éxito`,
					pdfURL: response.data.cotizacion.urlDocumento,
					reservationURL: rootState.general.projectData.webReservation
						? `${process.env.VUE_APP_RESERVATION_URL}/${rootState.general.realEstate}/proyecto/${rootState.general.projectData.projectName}?code=${response.data.cotizacion.codigo}`.replace(
								' ',
								'-'
						  )
						: '',
					reservationCode: response.data.cotizacion.codigo,
					id: response.data.cotizacion.id,
					name: inputs.fName,
					rooms: apartment.numeroDeDormitorios,
					assets: response.data.cotizacion.bienes,
					bathrooms: apartment.numeroDeBanos,
					orientation: apartment.orientacion,
					totalSurface: apartment.superficieTotal,
					utilSurface: apartment.superficieUtil,
					terraceSurface: apartment.superficieTerraza,
					amount: response.data.cotizacion.valorUf,
					total: response.data.cotizacion.totalCotizacion,
					paymentPlan: response.data.cotizacion.cotizacionFormaPago,
					creditInfo: response.data.cotizacion.glosaCreditoHipotecarioUno,
				}
				if (rootState.general.quoteLimit) {
					commit('general/setQuoteLimit', rootState.general.quoteLimit - 1, {
						root: true,
					})
				}

				commit('general/setQuoteNotification', payload, { root: true })
				commit('general/setFormSubmissionResponded', true, { root: true })
				state.sendingQuote = false
				let event = new CustomEvent('quote-response', {
					bubbles: true,
					detail: { result: 'success', data: response.data.cotizacion },
				})
				document.dispatchEvent(event)
			})
			.catch(error => {
				const payload = {
					success: false,
					title: 'ERROR',
					body: `Ocurrió un fallo al realizar la ${getters.getSlang.formData.quoteName.toLowerCase()} `,
				}
				commit('general/setQuoteNotification', payload, { root: true })
				commit('general/setFormSubmissionResponded', true, { root: true })
				state.sendingQuote = false
				let event = new CustomEvent('quote-response', {
					bubbles: true,
					detail: { result: 'error' },
				})
				document.dispatchEvent(event)
				console.error('[MobySuite Quote]', error.response.data.error)
			})
	},
	fetchDemographicData: (
		{ state, commit, rootState },
		{ table, mutationName, fieldName }
	) => {
		const version = rootState.general.version == 2 ? 'v3' : 'v2'
		Vue.axios
			.get(`${version}/quotes/list-demographic-info`, {
				params: {
					real_estate: rootState.general.realEstate,
					table,
				},
			})
			.then(response => commit(mutationName, response.data))
			.catch(error => {
				state.optionalFields[fieldName].set = false
				console.log(error)
			})
	},
	fetchDemographicAllData: ({ dispatch, state }) => {
		const communeData = {
			table: 'COMUNA',
			mutationName: 'setComunas',
			fieldName: 'commune',
			fetch: state.optionalFields.commune.set,
		}

		const ageRangedata = {
			table: 'RANGO_EDAD',
			mutationName: 'setAges',
			fieldName: 'ageRange',
			fetch: state.optionalFields.ageRange.set,
		}

		const professionData = {
			table: 'PROFESION',
			mutationName: 'setProfessions',
			fieldName: 'profession',
			fetch: state.optionalFields.profession.set,
		}

		const destinationPurchaseData = {
			table: 'DESTINO_COMPRA',
			mutationName: 'setPurchaseDestinations',
			fieldName: 'destinationPurchase',
			fetch: state.optionalFields.destinationPurchase.set,
		}

		const sexData = {
			table: 'SEXO',
			mutationName: 'setSexs',
			fieldName: 'sex',
			fetch: state.optionalFields.sex.set,
		}

		const rentRangeData = {
			table: 'RANGO_RENTA',
			mutationName: 'setRentRanges',
			fieldName: 'rentRange',
			fetch: state.optionalFields.rentRange.set,
		}

		const demographicData = [
			communeData,
			ageRangedata,
			professionData,
			destinationPurchaseData,
			sexData,
			rentRangeData,
		]

		demographicData.forEach(item => {
			if (item.fetch) {
				dispatch('fetchDemographicData', item)
			}
		})
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	getters,
	actions,
}
