import Vue from 'vue'

const state = {
	themeColors: {
		color1: null,
		color2: null,
		color3: null,
		inputColor1: null,
		inputColor2: null,
		selectColor: null,
		selectTextColor: null,
		infoAlertColor: null,
		infoAlertTextColor: null,
		buttonTextColor: null,
		totalColor: null,
		validationColor: null,
	},
	urlSuccess: null,
	successViewType: null,
	hideProjectData: false,
	innerSuccess: false,
}

const mutations = {
	setThemeColors: (state, data) => (state.themeColors = data),
	setSuccessViewType: (state, data) => (state.successViewType = data),
	setInnerSuccess: (state, data) => (state.innerSuccess = data),
	setUrlSuccess: (state, data) => (state.urlSuccess = data),
	setProjectDataVisibility: (state, data) => (state.hideProjectData = data),
}

const actions = {
	fetchRealEstateInfo: ({ rootState, state, commit }) => {
		const version = rootState.general.version == 2 ? 'v3' : 'v2'
		Vue.axios
			.get(`${version}/quotes/list-real-estate-info`, {
				params: {
					real_estate: rootState.general.realEstate,
					project_id: rootState.general.projectId,
				},
			})
			.then(response => {
				const payload = {
					color1: state.themeColors.color1
						? state.themeColors.color1
						: response.data.color_1,
					color2: state.themeColors.color2
						? state.themeColors.color2
						: response.data.color_2,
					color3: state.themeColors.color3
						? state.themeColors.color3
						: response.data.color_3,
					inputColor1: state.themeColors.inputColor1
						? state.themeColors.inputColor1
						: response.data.input_color_1,
					inputColor2: state.themeColors.inputColor2
						? state.themeColors.inputColor2
						: state.themeColors.inputColor2,
					selectColor: state.themeColors.selectColor
						? state.themeColors.selectColor
						: response.data.select_color,
					selectTextColor: state.themeColors.selectTextColor
						? state.themeColors.selectTextColor
						: response.data.select_text_color,
					infoAlertColor: state.themeColors.infoAlertColor
						? state.themeColors.infoAlertColor
						: response.data.info_alert_color,
					infoAlertTextColor: state.themeColors.infoAlertTextColor
						? state.themeColors.infoAlertTextColor
						: response.data.info_alert_text_color,
					buttonTextColor: state.themeColors.buttonTextColor,
				}
				if (state.urlSuccess == null) {
					state.urlSuccess = response.data.quotes_redirect_url
				}
				commit('setThemeColors', payload)
				commit('general/setVitalDataLoaded', 'theme', { root: true })
			})
			.catch(error => {
				commit('general/setAtLeastOneVitalResponseFailed', null, { root: true })
				commit('general/setVitalDataLoaded', 'theme', { root: true })
				console.log(error)
			})
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
}
