import { render, staticRenderFns } from "./ViewSuccess.vue?vue&type=template&id=c2be5f74&scoped=true&"
import script from "./ViewSuccess.vue?vue&type=script&lang=js&"
export * from "./ViewSuccess.vue?vue&type=script&lang=js&"
import style0 from "./ViewSuccess.vue?vue&type=style&index=0&id=c2be5f74&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2be5f74",
  null
  
)

export default component.exports