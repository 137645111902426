<template>
	<v-row
		no-gutters
		:style="quoteSuccessAndErrorAsDialog ? '' : 'min-height:100vh'"
		justify="center"
		align="center"
		style="background-color: #EEE"
	>
		<v-card
			v-if="!urlSuccess"
			class="pa-2"
			style="width: 100%; max-width: 1100px;"
		>
			<v-card-text class="text-center">
				<v-row justify="center" align="center">
					<v-col cols="12" :md="projectData.projectImageUrl ? 12 : 5">
						<banner
							:title="`${getSlang.formData.quoteName} #${quoteNotification.id}`"
							:subtitle="
								projectData.webReservation && quoteNotification.success
									? `Código de reserva: ${quoteNotification.reservationCode}`
									: ``
							"
							:icon="
								projectData.webReservation && quoteNotification.success
									? ['fas', 'barcode-alt']
									: ``
							"
							background-color="#FFF"
							text-color="#000"
							:mobile-style="projectData.projectImageUrl ? false : true"
						/>
					</v-col>
				</v-row>

				<v-row
					style="border-bottom: 5px solid #EEE"
					justify="center"
					align="center"
				>
					<v-col :md="projectData.projectImageUrl ? 12 : 5">
						<p class="text-body-1">
							{{ quoteNotification.name }}, gracias por
							{{ getSlang.quoteSlang.quoteAction.toLowerCase() }} en el proyecto
							{{ projectData.projectName }}, un ejecutivo te contactará en
							breve.
						</p>
					</v-col>
				</v-row>
				<v-row class="px-2 d-flex" align="center" justify="center">
					<v-col cols="12" sm="12" :md="projectData.projectImageUrl ? 6 : 5">
						<v-row>
							<p class="text-h6 font-weight-black">
								Resumen de {{ getSlang.formData.quoteName.toLowerCase() }}
							</p>
						</v-row>
						<v-row
							class="mb-5"
							align="center"
							justify="center"
							style="background-color: #EEE"
						>
							<div style="width:100%">
								<v-row>
									<v-col cols="4" align="center" justify="center">
										<p class="font-weight-black">Dormitorios</p>
										{{ quoteNotification.rooms }}</v-col
									>
									<v-col cols="4" align="center" justify="center">
										<p class="font-weight-black">Baños</p>
										{{ quoteNotification.bathrooms }}</v-col
									>
									<v-col cols="4" align="center" justify="center">
										<p class="font-weight-black">Orientación</p>
										{{ quoteNotification.orientation }}</v-col
									>
								</v-row>
								<v-row>
									<v-col cols="4" align="center" justify="center">
										<p class="font-weight-black">Superficie Total</p>
										{{
											new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
											}).format(quoteNotification.totalSurface)
										}}m<sup>2</sup></v-col
									>
									<v-col cols="4" align="center" justify="center">
										<p class="font-weight-black">Superficie Útil</p>
										{{
											new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
											}).format(quoteNotification.utilSurface)
										}}m<sup>2</sup></v-col
									>
									<v-col cols="4" align="center" justify="center">
										<p class="font-weight-black">Superficie Terraza</p>
										{{
											new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
											}).format(quoteNotification.terraceSurface)
										}}m<sup>2</sup></v-col
									>
								</v-row>
							</div>
						</v-row>
						<v-row>
							<p class="text-h6 font-weight-black">
								{{ getSlang.quoteSlang.quotedProducts }}
							</p>
						</v-row>
						<v-row>
							<table-sub-assets />
						</v-row>
						<v-row class="mt-6" align="center" justify="center">
							<v-btn
								v-if="quoteNotification.success"
								class="mx-4 mt-8"
								:color="themeColors.color3"
								:style="
									`color: ${themeColors.buttonTextColor}; font-size: 14px`
								"
								outlined
								@click="redirectToSite(quoteNotification.pdfURL)"
							>
								Ver PDF
							</v-btn>

							<v-btn
								v-if="projectData.webReservation && quoteNotification.success"
								class="mx-4 mt-8"
								:color="themeColors.color3"
								:style="
									`color: ${themeColors.buttonTextColor}; font-size: 14px`
								"
								@click="redirectToSite(quoteNotification.reservationURL)"
							>
								Reservar
							</v-btn>
						</v-row>
					</v-col>
					<v-col
						v-if="projectData.projectImageUrl"
						class="align-self-start"
						cols="12"
						sm="12"
						md="6"
					>
						<img width="100%" :src="projectData.projectImageUrl" />
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					:color="themeColors.color3"
					:style="`color: ${themeColors.buttonTextColor}; font-size: 14px`"
					dark
					@click="newQuote()"
				>
					Nueva cotización
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-row>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import PeruData from '@/Dictionaries/Peru.js'
import ChileData from '@/Dictionaries/Chile.js'
import MexicoData from '@/Dictionaries/Mexico.js'

import Banner from './Banner.vue'
import TableSubAssets from '@/components/TableSubAssets.vue'

export default {
	name: 'ViewSuccess',
	components: { Banner, TableSubAssets },
	computed: {
		...mapState('general', [
			'quoteNotification',
			'projectData',
			'quoteSuccessAndErrorAsDialog',
			'realEstateLogoUrl',
		]),
		...mapState('theme', ['urlSuccess', 'themeColors']),
		...mapGetters('form', ['getIsChilean']),
		...mapState('form', ['countryCode']),
		getSlang() {
			switch (this.countryCode) {
				case 'CL':
					return ChileData
				case 'PE':
					return PeruData
				case 'MX':
					return MexicoData
				default:
					return ChileData
			}
		},
	},
	created() {
		if (this.urlSuccess) {
			window.location.href = this.urlSuccess
		}
	},
	methods: {
		...mapMutations('general', ['setFormSubmissionResponded']),
		...mapActions('assets', ['resetSecondaryAssetsToQuote']),
		redirectToSite(url) {
			window.open(url)
		},
		newQuote() {
			this.setFormSubmissionResponded(false)
			this.resetSecondaryAssetsToQuote()
		},
	},
}
</script>
<style scoped>
.v-application p {
	margin: 0px;
}
</style>
