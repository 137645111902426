<template>
	<div
		:style="
			`background-color:${themeColors.color1}; color:${themeColors.color2}`
		"
	>
		<v-row no-gutters>
			<v-col cols="12" sm="12" md="7" lg="8">
				<div class="main-container">
					<p class="text-h4 font-weight-black">Reservar</p>
					<v-container class="filters-container">
						<v-row align="end">
							<v-col cols="12" sm="2" md="4" lg="2" xs="6">
								Precio <small>(UF)</small>
								<div style="display: flex">
									<v-text-field
										v-model="minPrice"
										class="mr-1"
										placeholder="mín"
										dense
									>
									</v-text-field>
									<v-text-field
										v-model="maxPrice"
										class="ml-1"
										placeholder="máx"
										dense
									>
									</v-text-field>
								</div>
							</v-col>
							<v-col cols="12" sm="2" md="4" lg="2" xs="6">
								Piso
								<v-autocomplete
									v-model="floor"
									dense
									placeholder="Piso"
									:items="getFloorOptions"
									multiple
								></v-autocomplete>
							</v-col>
							<v-col cols="12" sm="2" md="4" lg="2" xs="6">
								Tipología
								<v-autocomplete
									v-model="typology"
									dense
									placeholder="Tipología"
									:items="getTypologyOptions"
									multiple
								></v-autocomplete>
							</v-col>
							<v-col cols="12" sm="3" md="4" lg="3" xs="6">
								Orientación
								<v-autocomplete
									v-model="orientation"
									dense
									placeholder="Orientación"
									:items="getOrientationOptions"
									multiple
								></v-autocomplete>
							</v-col>
						</v-row>
						<v-row v-if="showExtraFilters">
							<v-col cols="12" sm="3" xs="6">
								Dormitorios
								<v-autocomplete
									v-model="rooms"
									dense
									placeholder="N° de dormitorios"
									:items="getRoomsAmounts"
									multiple
								></v-autocomplete>
							</v-col>
							<v-col cols="12" sm="3" xs="6">
								Baños
								<v-autocomplete
									v-model="bathrooms"
									dense
									placeholder="N° de baños"
									:items="getBathroomsAmounts"
									multiple
								></v-autocomplete>
							</v-col>
							<v-col cols="12" sm="3" xs="6">
								Superficie útil <small>m<sup>2</sup></small>
								<div style="display: flex">
									<v-text-field
										v-model="utilSurfaceMin"
										class="mr-1"
										placeholder="mín"
										dense
									>
									</v-text-field>
									<v-text-field
										v-model="utilSurfaceMax"
										class="ml-1"
										placeholder="máx"
										dense
									>
									</v-text-field>
								</div>
							</v-col>
						</v-row>
						<v-row id="filters" no-gutters justify="end">
							<div v-if="showExtraFilters" class="content">
								<p class="blue-text" @click="showExtraFilters = false">
									Menos filtros
								</p>
								<font-awesome-icon
									class="mx-1 mb-2 blue-text"
									style="font-size: 19px"
									:icon="['fas', 'minus-circle']"
									@click="showExtraFilters = false"
								/>
							</div>
							<div v-else class="content">
								<p class="blue-text" @click="showExtraFilters = true">
									Más filtros
								</p>
								<font-awesome-icon
									class="mx-1 mb-2 blue-text"
									style="font-size: 19px"
									:icon="['fas', 'plus-circle']"
									@click="showExtraFilters = true"
								/>
							</div>
						</v-row>
						<v-row justify="end">
							<v-btn
								class="rounded-xl filter-btn clean-btn"
								outlined
								color="#00718C"
								@click="resetData()"
							>
								<font-awesome-icon class="mx-1" :icon="['fas', 'eraser']" />
								Limpiar
							</v-btn>
							<v-btn
								color="#00718C"
								class="rounded-xl mx-2 filter-btn"
								dark
								@click="search()"
							>
								<font-awesome-icon class="mx-1" :icon="['fas', 'search']" />
								Buscar
							</v-btn>
						</v-row>
					</v-container>
				</div>

				<div style="overflow-x: auto; margin-right: 25px;">
					<table class="form-data-table sortable">
						<tr>
							<th></th>
							<th>
								<div class="th-title">
									Num
									<div class="arrows-container">
										<font-awesome-icon
											class="blue-text arrows"
											:icon="['fas', 'chevron-up']"
											@click="
												orderBy({
													type: 'number',
													field: 'assetNumber',
													order: 'major',
												})
											"
										/><font-awesome-icon
											class="blue-text arrows"
											:icon="['fas', 'chevron-down']"
											@click="
												orderBy({
													type: 'number',
													field: 'assetNumber',
													order: 'minor',
												})
											"
										/>
									</div>
								</div>
							</th>
							<th>
								<div class="th-title">
									Precio <small style="align-self: center;">(UF)</small>
									<div class="arrows-container">
										<font-awesome-icon
											class="blue-text arrows"
											:icon="['fas', 'chevron-up']"
											@click="
												orderBy({
													type: 'number',
													field: 'price',
													order: 'major',
												})
											"
										/><font-awesome-icon
											class="blue-text arrows"
											:icon="['fas', 'chevron-down']"
											@click="
												orderBy({
													type: 'number',
													field: 'price',
													order: 'minor',
												})
											"
										/>
									</div>
								</div>
							</th>
							<th>
								<div class="th-title">
									Dorms
									<div class="arrows-container">
										<font-awesome-icon
											class="blue-text arrows"
											:icon="['fas', 'chevron-up']"
											@click="
												orderBy({
													type: 'number',
													field: 'roomsAmount',
													order: 'major',
												})
											"
										/><font-awesome-icon
											class="blue-text arrows"
											:icon="['fas', 'chevron-down']"
											@click="
												orderBy({
													type: 'number',
													field: 'roomsAmount',
													order: 'minor',
												})
											"
										/>
									</div>
								</div>
							</th>
							<th>
								<div class="th-title">
									Baños
									<div class="arrows-container">
										<font-awesome-icon
											class="blue-text arrows"
											:icon="['fas', 'chevron-up']"
											@click="
												orderBy({
													type: 'number',
													field: 'bathroomsAmount',
													order: 'major',
												})
											"
										/><font-awesome-icon
											class="blue-text arrows"
											:icon="['fas', 'chevron-down']"
											@click="
												orderBy({
													type: 'number',
													field: 'bathroomsAmount',
													order: 'minor',
												})
											"
										/>
									</div>
								</div>
							</th>
							<th>
								<div class="th-title">
									S. total
									<div class="arrows-container">
										<font-awesome-icon
											class="blue-text arrows"
											:icon="['fas', 'chevron-up']"
											@click="
												orderBy({
													type: 'number',
													field: 'totalArea',
													order: 'major',
												})
											"
										/><font-awesome-icon
											class="blue-text arrows"
											:icon="['fas', 'chevron-down']"
											@click="
												orderBy({
													type: 'number',
													field: 'totalArea',
													order: 'minor',
												})
											"
										/>
									</div>
								</div>
							</th>
							<th>
								<div class="th-title">
									S. útil
									<div class="arrows-container">
										<font-awesome-icon
											class="blue-text arrows"
											:icon="['fas', 'chevron-up']"
											@click="
												orderBy({
													type: 'number',
													field: 'utilSurface',
													order: 'major',
												})
											"
										/><font-awesome-icon
											class="blue-text arrows"
											:icon="['fas', 'chevron-down']"
											@click="
												orderBy({
													type: 'number',
													field: 'utilSurface',
													order: 'minor',
												})
											"
										/>
									</div>
								</div>
							</th>
							<th>
								<div class="th-title">
									S. terraza
									<div class="arrows-container">
										<font-awesome-icon
											class="blue-text arrows"
											:icon="['fas', 'chevron-up']"
											@click="
												orderBy({
													type: 'number',
													field: 'terraceArea',
													order: 'major',
												})
											"
										/><font-awesome-icon
											class="blue-text arrows"
											:icon="['fas', 'chevron-down']"
											@click="
												orderBy({
													type: 'number',
													field: 'terraceArea',
													order: 'minor',
												})
											"
										/>
									</div>
								</div>
							</th>
							<th>
								<div class="th-title">
									Orientación
									<div class="arrows-container">
										<font-awesome-icon
											class="blue-text arrows"
											:icon="['fas', 'chevron-up']"
											@click="
												orderBy({
													type: 'text',
													field: 'orientation',
													order: 'major',
												})
											"
										/><font-awesome-icon
											class="blue-text arrows"
											:icon="['fas', 'chevron-down']"
											@click="
												orderBy({
													type: 'text',
													field: 'orientation',
													order: 'minor',
												})
											"
										/>
									</div>
								</div>
							</th>
							<th>
								<div class="th-title">
									Piso
									<div class="arrows-container">
										<font-awesome-icon
											class="blue-text arrows"
											:icon="['fas', 'chevron-up']"
											@click="
												orderBy({
													type: 'number',
													field: 'floor',
													order: 'major',
												})
											"
										/><font-awesome-icon
											class="blue-text arrows"
											:icon="['fas', 'chevron-down']"
											@click="
												orderBy({
													type: 'number',
													field: 'floor',
													order: 'minor',
												})
											"
										/>
									</div>
								</div>
							</th>
							<th>
								<div class="th-title">
									Tipo
									<div class="arrows-container">
										<font-awesome-icon
											class="blue-text arrows"
											:icon="['fas', 'chevron-up']"
											@click="
												orderBy({
													type: 'text',
													field: 'type',
													order: 'major',
												})
											"
										/><font-awesome-icon
											class="blue-text arrows"
											:icon="['fas', 'chevron-down']"
											@click="
												orderBy({
													type: 'text',
													field: 'type',
													order: 'minor',
												})
											"
										/>
									</div>
								</div>
							</th>
							<th>
								<div class="th-title">
									S. jardín
									<div class="arrows-container">
										<font-awesome-icon
											class="blue-text arrows"
											:icon="['fas', 'chevron-up']"
											@click="
												orderBy({
													type: 'number',
													field: 'gardenSurface',
													order: 'major',
												})
											"
										/><font-awesome-icon
											class="blue-text arrows"
											:icon="['fas', 'chevron-down']"
											@click="
												orderBy({
													type: 'number',
													field: 'gardenSurface',
													order: 'minor',
												})
											"
										/>
									</div>
								</div>
							</th>
						</tr>

						<tr v-for="asset in pageOfItems" :key="asset.id">
							<td>
								<input
									:id="`checkbox-${asset.id}`"
									type="checkbox"
									@click="updateSelection(asset.id)"
								/>
							</td>
							<td>{{ asset.number }}</td>
							<td>
								{{
									new Intl.NumberFormat('es-CL', {
										maximumFractionDigits: 2,
									}).format(asset.price)
								}}
							</td>
							<td>{{ asset.rooms > 0 ? asset.rooms : '-' }}</td>
							<td>{{ asset.bathrooms > 0 ? asset.bathrooms : '-' }}</td>
							<td v-if="asset.totalSurface != 0">
								{{
									new Intl.NumberFormat('es-CL', {
										maximumFractionDigits: 2,
									}).format(asset.totalSurface)
								}}
								m<sup>2</sup>
							</td>
							<td v-else>-</td>
							<td v-if="asset.utilSurface != 0">
								{{
									new Intl.NumberFormat('es-CL', {
										maximumFractionDigits: 2,
									}).format(asset.utilSurface)
								}}
								m<sup>2</sup>
							</td>
							<td v-else>-</td>
							<td v-if="asset.terraceArea != 0">
								{{
									new Intl.NumberFormat('es-CL', {
										maximumFractionDigits: 2,
									}).format(asset.terraceArea)
								}}
								m<sup>2</sup>
							</td>
							<td v-else>-</td>
							<td>{{ asset.orientation }}</td>
							<td>{{ asset.floor }}</td>
							<td>{{ asset.typology ? asset.typology : '-' }}</td>
							<td v-if="asset.gardenSurface && asset.gardenSurface != 0">
								{{
									new Intl.NumberFormat('es-CL', {
										maximumFractionDigits: 2,
									}).format(asset.gardenSurface)
								}}
								m<sup>2</sup>
							</td>
							<td v-else>-</td>
						</tr>
					</table>
				</div>
				<v-row justify="end" class="mx-2 mt-2 mb-2">
					<jw-pagination
						style="z-index: 0 !important;"
						:items="getFilteredAssets"
						:labels="customLabels"
						:max-pages="4"
						:page-size="7"
						@changePage="onChangePage"
					></jw-pagination>
				</v-row>
			</v-col>
			<v-col cols="12" sm="12" md="5" lg="4" class="data-element">
				<v-expansion-panels flat :value="0" class="data-element">
					<v-expansion-panel>
						<v-expansion-panel-header>
							<p class="text-subtitle-1 mb-1 pl-1">
								<v-avatar class="mr-3" color="#00718C" size="24">
									<span :style="`font-size: 15px; color: #FFF`">1</span>
								</v-avatar>
								<b>Detalle del bien</b>
							</p>
						</v-expansion-panel-header>
						<v-expansion-panel-content v-if="getAssetSelectedStatus">
							<v-row justify="center">No hay bienes seleccionados</v-row>
						</v-expansion-panel-content>
						<v-expansion-panel-content v-else>
							<v-row class="mb-1" align="center" justify="center">
								<v-col cols="12" sm="6" xs="12">
									<v-row align="center" justify="center" no-gutters>
										<v-col class="px-2">
											<image-dialog
												:thumb="`${getApartmentInfo.image2}`"
												:full="`${getApartmentInfo.image2}`"
												:mobile="
													this.$vuetify.breakpoint.name == 'xs' ||
													this.$vuetify.breakpoint.name == 'sm'
														? true
														: false
												"
												:style="
													` max-height: 400px: max-width:${imgWidth}px ${
														sendingQuote
															? ''
															: 'z-index: 200; position:relative;'
													} `
												"
											></image-dialog>
										</v-col>
									</v-row>
								</v-col>
								<v-col cols="12" sm="6" xs="12">
									<v-row align="center" justify="center" no-gutters>
										<v-col class="px-2">
											<image-dialog
												:thumb="`${getApartmentInfo.image3}`"
												:full="`${getApartmentInfo.image3}`"
												:mobile="
													this.$vuetify.breakpoint.name == 'xs' ||
													this.$vuetify.breakpoint.name == 'sm'
														? true
														: false
												"
												:style="
													` max-height: 400px: max-width:${imgWidth}px ${
														sendingQuote
															? ''
															: 'z-index: 200; position:relative;'
													} `
												"
											></image-dialog>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
							<v-row style="margin-top: 0px" no-gutters>
								<v-col cols="4" justify="start" align="start">
									<v-tooltip v-if="$vuetify.breakpoint.name == 'xs'" top>
										<template v-slot:activator="{ on, attrs }">
											<font-awesome-icon
												class="mx-1 icon"
												:icon="['fal', 'bed-alt']"
												v-bind="attrs"
												v-on="on"
											/>
										</template>
										<span>Dormitorios</span>
									</v-tooltip>
									<font-awesome-icon
										v-else
										class="mx-1 icon"
										:icon="['fal', 'bed-alt']"
									/>
									{{
										this.$vuetify.breakpoint.name != 'xs' ? 'Dormitorios:' : ''
									}}
									{{ getApartmentInfo.rooms }}
								</v-col>
								<v-col cols="4" justify="start" align="start">
									<v-tooltip v-if="$vuetify.breakpoint.name == 'xs'" top>
										<template v-slot:activator="{ on, attrs }">
											<font-awesome-icon
												class="mx-1 icon"
												:icon="['fal', 'bath']"
												v-bind="attrs"
												v-on="on"
											/>
										</template>
										<span>Baños</span>
									</v-tooltip>
									<font-awesome-icon
										v-else
										class="mx-1 icon"
										:icon="['fal', 'bath']"
									/>
									{{ this.$vuetify.breakpoint.name != 'xs' ? 'Baños:' : '' }}
									{{ getApartmentInfo.bathrooms }}
								</v-col>
								<v-col cols="4" justify="start" align="start">
									<v-tooltip top>
										<template v-slot:activator="{ on, attrs }">
											<font-awesome-icon
												class="mx-1 icon"
												:icon="['fal', 'compass']"
												v-bind="attrs"
												v-on="on"
											/>
										</template>
										<span>Orientación</span>
									</v-tooltip>
									{{
										getApartmentInfo.orientation
											? getApartmentInfo.orientation[0].toUpperCase() +
											  getApartmentInfo.orientation.substring(1).toLowerCase()
											: '-'
									}}
								</v-col>
							</v-row>
							<v-row style="margin-top: 0px" no-gutters>
								<v-col cols="4" justify="start" align="start">
									<v-tooltip v-if="$vuetify.breakpoint.name == 'xs'" top>
										<template v-slot:activator="{ on, attrs }">
											<font-awesome-icon
												class="mx-1 icon"
												:icon="['fal', 'ruler-combined']"
												v-bind="attrs"
												v-on="on"
											/>
										</template>
										<span>Superficie total</span>
									</v-tooltip>
									<font-awesome-icon
										v-else
										class="mx-1 icon"
										:icon="['fal', 'ruler-combined']"
									/>
									{{ this.$vuetify.breakpoint.name != 'xs' ? 'Total:' : '' }}
									{{
										new Intl.NumberFormat('es-CL', {
											maximumFractionDigits: 2,
										}).format(getApartmentInfo.surface)
									}}
									m<sup>2</sup>
								</v-col>
								<v-col cols="4" justify="start" align="start">
									<v-tooltip v-if="$vuetify.breakpoint.name == 'xs'" top>
										<template v-slot:activator="{ on, attrs }">
											<font-awesome-icon
												class="mx-1 icon"
												:icon="['fal', 'ruler-combined']"
												v-bind="attrs"
												v-on="on"
											/>
										</template>
										<span>Superficie Útil</span>
									</v-tooltip>
									<font-awesome-icon
										v-else
										class="mx-1 icon"
										:icon="['fal', 'ruler-combined']"
									/>
									{{ this.$vuetify.breakpoint.name != 'xs' ? 'Útil:' : '' }}
									{{ getApartmentInfo.util }} m<sup>2</sup>
								</v-col>
								<v-col cols="4" justify="start" align="start">
									<v-tooltip v-if="$vuetify.breakpoint.name == 'xs'" top>
										<template v-slot:activator="{ on, attrs }">
											<font-awesome-icon
												class="mx-1 icon"
												:icon="['fal', 'seedling']"
												v-bind="attrs"
												v-on="on"
											/>
										</template>
										<span>Terraza</span>
									</v-tooltip>
									<font-awesome-icon
										v-else
										class="mx-1 icon"
										:icon="['fal', 'seedling']"
									/>
									{{ this.$vuetify.breakpoint.name != 'xs' ? 'Tza:' : '' }}
									{{ getApartmentInfo.terrace }} m<sup>2</sup>
								</v-col>
							</v-row>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
				<v-expansion-panels flat :value="0" style="z-index: 0 !important;">
					<v-expansion-panel>
						<v-expansion-panel-header>
							<p class="text-subtitle-1 pl-1">
								<v-avatar class="mr-3" color="#00718C" size="24">
									<span :style="`font-size: 15px; color: #FFF`">2</span>
								</v-avatar>
								<b>Bienes cotizados</b>
							</p>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-row v-if="getAssetSelectedStatus" justify="center"
								>No hay bienes seleccionados</v-row
							>
							<v-row
								v-else
								class="px-1"
								align="center"
								justify="center"
								no-gutters
							>
								<table-sub-assets
									:show-total="true"
									:from-mainview="true"
									background-color="#f3fbfc"
									headers-color="#00718c"
									:without-borders="true"
									:delete-items="true"
									text-align="start"
									@removeAsset="removeAsset"
								/>
								<div class="reservation-data">
									<small>Reserva abonable al pie</small
									><small
										style="text-decoration: underline; cursor: pointer;"
										class="blue-text"
										@click="
											redirectToSite(
												'https://www.euroinmobiliaria.cl/bases-notariales/'
											)
										"
									>
										Ver bases y condiciones
									</small>
								</div>
								<div class="reservation-amount">
									<small
										>Monto reserva:
										{{
											new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
											}).format(getPaymentData.reservation.localCurrencyValue)
										}}
										CLP</small
									>
								</div>
							</v-row>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
				<v-expansion-panels flat :value="0" style="z-index: 0 !important;">
					<v-expansion-panel>
						<v-expansion-panel-header>
							<p class="text-subtitle-1 pl-1">
								<v-avatar class="mr-3" color="#00718C" size="23">
									<span :style="`font-size: 15px; color: #FFF`">3</span>
								</v-avatar>
								<b>Ingresa tus datos de contacto</b>
							</p>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<quote-form ref="formRef" class="mt-2" />
							<v-row no-gutters>
								<v-col align="end">
									<v-btn
										id="moby-reservation-button"
										color="#FF671D"
										large
										class="mt-2 ml-sm-2 rounded-xl"
										dark
										:disabled="getAssetSelectedStatus"
										@click="$refs.formRef.submitForm(true)"
									>
										<font-awesome-icon :icon="['fal', 'hand-holding-usd']" />
										Reservar
									</v-btn>
								</v-col>
							</v-row>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-col>
		</v-row>
	</div>
</template>

<script>
const customLabels = {
	first: 'Primero',
	last: 'Último',
	previous: '<',
	next: '>',
}

import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import QuoteForm from '@/components/QuoteForm.vue'
import texts from '../mixins/TemplateTexts'
import TableSubAssets from '@/components/TableSubAssets.vue'
import ImageDialog from '@/components/ImageDialog.vue'

export default {
	name: 'ReserveInvestors',
	components: {
		QuoteForm,
		TableSubAssets,
		ImageDialog,
	},
	mixins: [texts],
	data() {
		return {
			rooms: [],
			bathrooms: [],
			utilSurfaceMin: null,
			utilSurfaceMax: null,
			orientation: [],
			floor: [],
			typology: [],
			minPrice: null,
			maxPrice: null,
			pageOfItems: [],
			customLabels,
			showExtraFilters: false,
		}
	},
	computed: {
		...mapState('form', ['countryCode', 'sendingQuote']),
		...mapState('general', [
			'projectData',
			'quoteLimit',
			'reservation',
			'hideSelectedAssets',
		]),
		...mapState('theme', ['themeColors']),
		...mapState('assets', ['apartmentId']),
		...mapGetters('assets', [
			'getApartmentInfo',
			'getBathroomsAmounts',
			'getRoomsAmounts',
		]),
		...mapGetters('reserveInvestors', [
			'getFilteredAssets',
			'getOrientationOptions',
			'getFloorOptions',
			'getTypologyOptions',
			'getAssetSelectedStatus',
		]),
		...mapGetters('general', ['getPaymentData']),
		imgWidth() {
			return this.$vuetify.breakpoint.name == 'xs' ? '90%' : 540
		},
	},
	created() {
		this.setThemeColors({
			inputColor1: '#eef8fa',
		})
		this.setFilteredAssets(null)
	},
	mounted() {
		let event = new Event('template-ready', { bubbles: true })
		document.dispatchEvent(event)
		this.setSkipSecondaryAssetsValidation(true)
		this.setMultipleMasterAssetSelectionPermission(true)
	},
	methods: {
		...mapMutations('theme', ['setThemeColors']),
		...mapMutations('assets', [
			'setApartmentId',
			'setMultipleMasterAssetSelectionPermission',
		]),
		...mapMutations('reserveInvestors', [
			'setSelectionBlocked',
			'setNoAssetSelected',
			'setSkipSecondaryAssetsValidation',
		]),
		...mapMutations('general', ['setSecondaryAssetsValidation']),
		...mapActions('reserveInvestors', ['setFilteredAssets', 'orderBy']),
		...mapActions('general', ['fetchPaymentPlan']),
		...mapActions('assets', ['updateMasterAssetsSelected']),
		onChangePage(pageOfItems) {
			this.pageOfItems = pageOfItems
		},
		updateSelection(id) {
			if (document.getElementById(`checkbox-${id}`).checked == true) {
				this.setApartmentId(id)
				this.setNoAssetSelected(false)
				this.updateMasterAssetsSelected({ action: 'add', assetId: id })
			} else {
				this.updateMasterAssetsSelected({ action: 'remove', assetId: id })
			}
			this.fetchPaymentPlan()
		},
		resetData() {
			this.rooms = []
			this.bathrooms = []
			this.utilSurfaceMin = null
			this.utilSurfaceMax = null
			this.orientation = []
			this.floor = []
			this.typology = []
			this.minPrice = null
			this.maxPrice = null
			this.setFilteredAssets(null)
		},
		search() {
			const filters = [
				{
					name: 'price',
					data: {
						min: this.minPrice,
						max: this.maxPrice,
					},
					type: 'range',
				},
				{
					name: 'roomsAmount',
					data: this.rooms,
					type: 'selection',
				},
				{
					name: 'bathroomsAmount',
					data: this.bathrooms,
					type: 'selection',
				},
				{
					name: 'utilSurface',
					data: {
						min: this.utilSurfaceMin,
						max: this.utilSurfaceMax,
					},
					type: 'range',
				},
				{
					name: 'orientation',
					data: this.orientation,
					type: 'selection',
				},
				{
					name: 'floor',
					data: this.floor,
					type: 'selection',
				},
				{
					name: 'type',
					data: this.typology,
					type: 'selection',
				},
			]
			this.setFilteredAssets(filters)
		},
		redirectToSite(url) {
			window.open(url)
		},
		removeAsset(id) {
			document.getElementById(`checkbox-${id}`).checked = false
		},
	},
}
</script>
<style lang="scss">
mobysuite-quote {
	.v-expansion-panels > .v-expansion-panel {
		background-color: #f3fbfc !important;
	}
	> .v-expansion-panel-content > .v-expansion-panel-content__wrap {
		padding-bottom: 0px !important;
	}
	> .v-expansion-panel-header > .v-expansion-panel-header__icon > .v-icon {
		font-size: 16px !important;
	}

	.filters-container {
		background: #eef8fa;
		padding: 15px 30px 15px 30px !important;
		border-radius: 20px;
		margin-top: 15px;
		> p {
			font-size: 16px;
		}
	}
	.data-element {
		background: #f7fbfc;
	}
	.form-data-table {
		padding: 20px;
		background-color: #fff;
		text-align: center;
		border-radius: 20px;
		width: 100%;
	}
	.form-data-table th {
		color: #00718c;
		background-color: #fff;
		max-width: 165px;
		padding: 5px;
		text-align: center;
		min-width: 90px;
	}
	.form-data-table tr:nth-child(even) {
		background-color: #f5f5f5;
	}
	.form-data-table tr:nth-child(odd) {
		background-color: #dfdcff;
	}
	.form-data-table tr {
		border-radius: 5px;
	}
	.form-data-table td {
		padding: 15px;
		text-overflow: ellipsis;
		overflow: hidden;
		max-width: 165px;
		white-space: nowrap;
		text-align: center;
	}
	.filter-btn {
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
	}
	.clean-btn {
		background-color: #fff;
	}
	.blue-text {
		color: #00718c;
	}
	.content {
		display: contents;
	}
	.reservation-data {
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin-top: 15px;
	}
	.reservation-amount {
		text-align: left;
		width: 100% !important;
	}
	.arrows {
		font-size: 8px;
	}
	.arrows-container {
		display: grid;
		margin-left: 5px;
	}
	.th-title {
		display: flex;
		text-align: center;
		justify-content: center;
	}
}
</style>
<style scoped lang="scss">
mobysuite-quote {
	.v-application p {
		margin: 0px;
	}
	.icon {
		font-size: 20px;
	}
	.main-container {
		padding: 20px;
	}
}
</style>
