<template>
	<v-row
		no-gutters
		:style="quoteSuccessAndErrorAsDialog ? '' : 'min-height:100vh'"
		justify="center"
		align="center"
		style="background-color: #EEE"
	>
		<v-card
			v-if="!urlSuccess"
			class="px-2 py-4"
			:style="
				this.$vuetify.breakpoint.name == 'sm' ||
				this.$vuetify.breakpoint.name == 'xs'
					? 'max-width: 550px'
					: 'max-width: 1100px'
			"
		>
			<v-card-text>
				<v-row class="px-2" align="center" justify="center">
					<v-col cols="12" sm="12" :md="projectData.projectImageUrl ? 6 : 12">
						<v-row no-gutters class="d-block">
							<p class="black--text text-h4">
								<b>
									{{ quoteNotification.name.toUpperCase() }}
								</b>
							</p>
							<p class="text-h4">
								<b>
									GRACIAS POR
									{{ getSlang.quoteSlang.quoteAction.toUpperCase() }}
								</b>
							</p>
							<p class="mt-1 text-body-1">
								UN EJECUTIVO TE CONTACTARÁ EN BREVE.
							</p>
							<br />
							<p
								class="text-h6 font-weight-black"
								:style="`color: ${themeColors.infoAlertColor}`"
							>
								RESUMEN DE {{ getSlang.formData.quoteName.toUpperCase() }} Nº
								{{
									new Intl.NumberFormat('es-CL', {
										maximumFractionDigits: 2,
									}).format(quoteNotification.id)
								}}
							</p>
						</v-row>
						<v-row no-gutters>
							<table-sub-assets :show-total="true" />
						</v-row>
						<v-row no-gutters class="d-flex justify-md-space-between">
							<v-btn
								v-if="quoteNotification.success"
								cols="12"
								xs="12"
								sm="6"
								class="mr-1 mt-4 text--caption flex-sm-grow-1 flex-sm--shrink-0"
								:width="this.$vuetify.breakpoint.name == 'xs' ? '100%' : ''"
								color="grey darken-3 white--text"
								style="font-size: 14px"
								@click="redirectToSite(quoteNotification.pdfURL)"
							>
								Descargar cotización
							</v-btn>
							<v-btn
								cols="12"
								xs="12"
								sm="6"
								:width="this.$vuetify.breakpoint.name == 'xs' ? '100%' : ''"
								color="grey darken-3 white--text flex-sm-grow-1 flex-sm--shrink-0"
								class="sm-ml-1 mt-4"
								style="font-size: 14px"
								dark
								@click="newQuote()"
							>
								Nueva cotización
							</v-btn>
						</v-row>
						<v-row no-gutters align="center" justify="center">
							<v-btn
								v-if="projectData.webReservation && quoteNotification.success"
								min-height="50px"
								min-width="100%"
								class="mx-1 mt-3 font-weight-black h1"
								:color="themeColors.infoAlertColor"
								:style="`color: ${themeColors.infoAlertTextColor}`"
								style="font-size: 24px"
								@click="redirectToSite(quoteNotification.reservationURL)"
							>
								Reserva online
							</v-btn>
						</v-row>
					</v-col>
					<v-col
						v-if="projectData.projectImageUrl"
						class="align-stretch"
						cols="12"
						sm="12"
						md="6"
					>
						<img
							v-if="this.$vuetify.breakpoint.name == 'xs'"
							width="100%"
							:src="projectData.projectImageUrl"
						/>

						<v-container
							v-else
							class="fill-height"
							fluid
							:style="
								`min-height: 450px; min-width: 450px; background-size: cover; background-repeat: no-repeat;
        				background-position: center; background-image: url( ${projectData.projectImageUrl});`
							"
						>
						</v-container>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-row>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import PeruData from '@/Dictionaries/Peru.js'
import ChileData from '@/Dictionaries/Chile.js'
import MexicoData from '@/Dictionaries/Mexico.js'
import TableSubAssets from '@/components/TableSubAssets.vue'

export default {
	name: 'ViewSuccessSimple',
	components: { TableSubAssets },
	computed: {
		...mapState('general', [
			'quoteNotification',
			'projectData',
			'quoteSuccessAndErrorAsDialog',
			'realEstateLogoUrl',
		]),
		...mapState('theme', ['urlSuccess', 'themeColors']),
		...mapGetters('form', ['getIsChilean']),
		...mapGetters('assets', ['getFinalAssetsAmount']),
		...mapState('form', ['countryCode']),
		getSlang() {
			switch (this.countryCode) {
				case 'CL':
					return ChileData
				case 'PE':
					return PeruData
				case 'MX':
					return MexicoData
				default:
					return ChileData
			}
		},
	},
	created() {
		if (this.urlSuccess) {
			window.location.href = this.urlSuccess
		}
	},
	methods: {
		...mapMutations('general', ['setFormSubmissionResponded']),
		...mapActions('assets', ['resetSecondaryAssetsToQuote']),
		newQuote() {
			this.setFormSubmissionResponded(false)
			this.resetSecondaryAssetsToQuote()
		},
		redirectToSite(url) {
			window.open(url)
		},
	},
}
</script>
<style scoped>
.v-application p {
	margin: 1px;
}
</style>
